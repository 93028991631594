import axios from "axios";
import React, { useEffect, useState } from "react";
import { GET_DASHBOARD_DATA, GET_SHOPS } from "../routes";
import Select from "react-select";

const Dashboard = () => {
  const [loggedInUser, setLoggedInUser] = useState();

  const [loading, setLoading] = useState(true);

  const [dashboardData, setDashboardData] = useState(null);
  const [staticDashboardData, setStaticDashboardData] = useState(null);

  const [shops, setShops] = useState([]);

  const getDashboardData = async () => {
    setLoading(true);
    let res = await axios.post(GET_DASHBOARD_DATA);
    setStaticDashboardData({
      activeShops: res.data.activeShops,
      inActiveShops: res.data.inActiveShops,
      freePlanMerchant: res.data.freePlanMerchant,
      basicPlanMerchant: res.data.basicPlanMerchant,
      mediumPlanMerchant: res.data.mediumPlanMerchant,
      advancePlanMerchant: res.data.advancePlanMerchant,
      customPlanMerchant: res.data.customPlanMerchant,
      shop_money_format: res.data.shop_money_format,
    });
    setDashboardData(res.data);
    setLoading(false);
  };

  const options = [{ value: "", label: "Select Store" }];

  const getShops = async () => {
    const result = await axios.post(GET_SHOPS, { all: true });
    setShops(result.data);
  };

  shops &&
    shops.map((value) =>
      options.push({
        value: value.id,
        label: value.shop,
      })
    );

  const getSelectedStore = async (event) => {
    setDashboardData(null);
    let res = await axios.post(GET_DASHBOARD_DATA, { shop_id: event.value });
    setDashboardData(res.data);
  };

  useEffect(() => {
    let user = localStorage.getItem("user");
    setLoggedInUser(JSON.parse(user));
    getDashboardData();
    getShops();
  }, []);

  return (
    <>
      <div className="container mt-3">
        <div
          className="row"
          style={{ border: "1px solid 1px solid #80808063", padding: "12px 0" }}
        >
          <div className="col-md-3">
            <div className="dashboard-card">
              <h5>Active Stores</h5>
              <div>
                {staticDashboardData != null ? (
                  staticDashboardData?.activeShops
                ) : (
                  <div
                    id="loader"
                    style={{ height: "18px", width: "18px" }}
                  ></div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="dashboard-card">
              <h5>In-Active Stores</h5>
              <div>
                {staticDashboardData != null ? (
                  staticDashboardData?.inActiveShops
                ) : (
                  <div
                    id="loader"
                    style={{ height: "18px", width: "18px" }}
                  ></div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="dashboard-card">
              <h5>Free Plan Merchants</h5>
              <div>
                {staticDashboardData != null ? (
                  staticDashboardData?.freePlanMerchant
                ) : (
                  <div
                    id="loader"
                    style={{ height: "18px", width: "18px" }}
                  ></div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="dashboard-card">
              <h5>Basic Plan Merchants</h5>
              <div>
                {staticDashboardData != null ? (
                  staticDashboardData?.basicPlanMerchant
                ) : (
                  <div
                    id="loader"
                    style={{ height: "18px", width: "18px" }}
                  ></div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="row"
          style={{ border: "1px solid 1px solid #80808063", padding: "12px 0" }}
        >
          <div className="col-md-3">
            <div className="dashboard-card">
              <h5>Medium Plan Merchants</h5>
              <div>
                {staticDashboardData != null ? (
                  staticDashboardData?.mediumPlanMerchant
                ) : (
                  <div
                    id="loader"
                    style={{ height: "18px", width: "18px" }}
                  ></div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="dashboard-card">
              <h5>Advance Plan Merchants</h5>
              <div>
                {staticDashboardData != null ? (
                  staticDashboardData?.advancePlanMerchant
                ) : (
                  <div
                    id="loader"
                    style={{ height: "18px", width: "18px" }}
                  ></div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="dashboard-card">
              <h5>Custom Plan Merchants</h5>
              <div>
                {staticDashboardData != null ? (
                  staticDashboardData?.customPlanMerchant
                ) : (
                  <div
                    id="loader"
                    style={{ height: "18px", width: "18px" }}
                  ></div>
                )}
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row my-3">
          <div className="col-md-4">
            {shops.length ? (
              <Select
                label="Select Store"
                value={options[0]}
                options={options}
                onChange={(e) => getSelectedStore(e)}
              />
            ) : (
              <div id="loader" style={{ height: "25px", width: "25px" }}></div>
            )}
          </div>
        </div>
        <div
          className="row"
          style={{ border: "1px solid 1px solid #80808063", padding: "12px 0" }}
        >
          <div className="col-md-3">
            <div className="dashboard-card">
              <h5>Total Cashback</h5>
              <div>
                {dashboardData != null ? (
                  staticDashboardData.shop_money_format +
                  "" +
                  dashboardData?.totalCashback
                ) : (
                  <div
                    id="loader"
                    style={{ height: "18px", width: "18px" }}
                  ></div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="dashboard-card">
              <h5>Redeemed Cashback</h5>
              <div>
                {dashboardData != null ? (
                  staticDashboardData.shop_money_format +
                  "" +
                  dashboardData?.redeemedCashback
                ) : (
                  <div
                    id="loader"
                    style={{ height: "18px", width: "18px" }}
                  ></div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="dashboard-card">
              <h5>Pending Cashback</h5>
              <div>
                {dashboardData != null ? (
                  staticDashboardData.shop_money_format +
                  "" +
                  dashboardData?.totalPendingCashback
                ) : (
                  <div
                    id="loader"
                    style={{ height: "18px", width: "18px" }}
                  ></div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="dashboard-card">
              <h5>Total Offers</h5>
              <div>
                {dashboardData != null ? (
                  dashboardData?.totalCampaigns
                ) : (
                  <div
                    id="loader"
                    style={{ height: "18px", width: "18px" }}
                  ></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
