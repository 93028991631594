import React, { useState } from "react";

import { AppLogo } from "../../Resources/Images";
import axios from "axios";
import { LOGIN } from "../../routes";
import { Navigate, useNavigate } from "react-router-dom";
import { IsLoggedIn as isLoggedIn } from "./Auth";
import { ToastContainer, toast } from 'react-toastify';

const Login = () => {
    const navigate = useNavigate();
    const [payload, setPayload] = useState({
        email: "",
        password: "",
    });
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 1500));
        let result = await axios.post(LOGIN, payload);
        if (result.data.status === 1) {
            localStorage.setItem("token", result.data.token);
            localStorage.setItem("user", JSON.stringify(result.data.user));
            toast.promise(
                resolveAfter3Sec,
                {
                    pending: 'Loading...',
                    success: 'Login Success',
                    error: 'Error!'
                }
            );
            setTimeout(() => {
                navigate('admin');
            }, 2500);
        } else {
            setError(result.data.message);
        }
    };

    return (!isLoggedIn() ?
        <div className="body-container">
            <ToastContainer autoClose={1500} />
            <div className="sub-container">
                <div className="card">
                    <form onSubmit={handleSubmit} id="login-form">
                        <div className="heading-img text-center">
                            <img src={AppLogo} alt="Logo" className="head-img" />
                        </div>
                        <div className="form-heading">
                            Sign In
                        </div>
                        <input type="email" className="login-page-input" placeholder="Email" onChange={(e) => setPayload({ ...payload, email: e.target.value })} />
                        <input type="password" className="login-page-input" placeholder="Password" onChange={(e) => setPayload({ ...payload, password: e.target.value })} />
                        <span className="text-danger">{error}</span>
                        <div className="buttons">
                            <button className="sign-in-button">
                                <i className="fa-solid fa-right-to-bracket"></i> Sign In
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div> : <Navigate to={"/admin"} />
    );
};

export default Login;
