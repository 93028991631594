import { HashRouter, Route, Routes } from "react-router-dom";
import Login from "./components/auth/Login";
import Layout from "./components/Layout";
import StoresLayout from "./components/StoresLayout";
import Dashboard from "./components/Dashboard";
import "react-toastify/dist/ReactToastify.css";
import TrackCustomer from "./components/TrackCustomer";
import ActiveStores from "./components/ActiveStores";
import InActiveStores from "./components/InActiveStores";
import DemoStores from "./components/DemoStores";
import Activity from "./components/Activity";
import CustomPlan from "./components/CustomPlan";
import PlanReminders from "./components/PlanReminders";
import CommonPropsContextProvider from "./contexts/CommonPropsContext";
import OrderIndex from "./components/OrderIndex";
import PlanHistories from "./components/PlanHistories";
import CustomerIndex from "./components/CustomerIndex";

function App() {
  return (
    <div>
      <CommonPropsContextProvider>
        <HashRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="admin" element={<Layout />}>
              <Route index element={<Dashboard />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="orders" element={<OrderIndex />} />
              <Route path="customers" element={<CustomerIndex />} />
              <Route path="track-customer" element={<TrackCustomer />} />
              <Route path="stores" element={<StoresLayout />}>
                <Route path="active" element={<ActiveStores />} />
                <Route path="in-active" element={<InActiveStores />} />
                <Route path="demo" element={<DemoStores />} />
                <Route path="activity/:id" element={<Activity />} />
              </Route>
              <Route path="custom-plan" element={<CustomPlan />} />
              <Route path="plan-reminders" element={<PlanReminders />} />
              <Route path="plan-history" element={<PlanHistories />} />
            </Route>
          </Routes>
        </HashRouter>
      </CommonPropsContextProvider>
    </div>
  );
}

export default App;
