import { useContext } from "react";
import { CommonPropsContext } from "../contexts/CommonPropsContext";
import Customer from "./Customer";

const CustomerIndex = () => {
  const { dbConnections, loadingContext } = useContext(CommonPropsContext);

  return (
    <>
      {!loadingContext ? (
        <>
          {dbConnections &&
            dbConnections.map((connection_key, index) => (
              <div key={index}>
                <Customer connection_key={connection_key} />
              </div>
            ))}
        </>
      ) : (
        <div id="loader"></div>
      )}
    </>
  );
};

export default CustomerIndex;
