import Header from './Header';
import Footer from './Footer';
import { Navigate, Outlet } from 'react-router-dom';
import { IsLoggedIn as isLoggedIn } from './auth/Auth';

const Layout = () => {
    return (isLoggedIn() ?
        <div className="container">
            <Header />
            <Outlet />
            <Footer />
        </div> : <Navigate to={"/"} />
    )
}

export default Layout