import moment from "moment";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { GET_CUSTOMERS, PROCESS_CUSTOMER } from "../routes";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const Customer = ({ connection_key }) => {
  const postData = { connection_key: connection_key };
  const [customers, setCustomers] = useState([]);

  const [processing, setProcessing] = useState(false);

  const [filters, setFilters] = useState({
    search: "",
    status: "",
  });

  const [page, setPage] = useState(1);

  const getCustomers = async () => {
    setProcessing(true);
    let result = await axios.post(GET_CUSTOMERS + "?page=" + page, postData);
    await setCustomers(result.data);
    setProcessing(false);
  };

  const handleNextPage = () => {
    if (customers.next_page_url !== null) {
      setPage(page + 1);
    }
  };

  const handlePrevPage = () => {
    if (customers.prev_page_url !== null) {
      setPage(page - 1);
    }
  };

  const copyToClipboard = (order_id) => {
    var copyText = document.getElementById("response_text" + order_id);
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
    toast("Copied!");
  };

  const tooltip = (
    <Tooltip id="tooltip">
      <strong>Copy Response</strong>
    </Tooltip>
  );

  const paginationTooltipPrev = (
    <Tooltip id="paginationTooltipPrev">
      <strong>Prev</strong>
    </Tooltip>
  );

  const paginationTooltipNext = (
    <Tooltip id="paginationTooltipNext">
      <strong>Next</strong>
    </Tooltip>
  );

  const processCustomerTooltip = (
    <Tooltip id="processCustomerTooltip">
      <strong>Process Customer</strong>
    </Tooltip>
  );

  const searchTooltip = (
    <Tooltip id="searchTooltip">
      <strong>Search</strong>
    </Tooltip>
  );

  const resetTooltip = (
    <Tooltip id="resetTooltip">
      <strong>Reset</strong>
    </Tooltip>
  );

  const formatDateTime = (value) => {
    return moment(value).format("MMMM Do YYYY, h:mm:ss a");
  };

  const performFilter = async () => {
    setProcessing(true);
    let result = await axios.post(
      GET_CUSTOMERS + "?page=" + page + "&filters=" + JSON.stringify(filters),
      postData
    );
    await setCustomers(result.data);
    setProcessing(false);
  };

  const processCustomer = async (customer_id) => {
    setProcessing(true);
    const res = await axios.post(PROCESS_CUSTOMER, {
      ...postData,
      customer_id: customer_id,
    });
    if (res.data.status === 1) {
      toast.success(res.data.message);
    } else {
      toast.error(res.data.message);
    }
    await setPage(0);
    setPage(1);
  };

  const resetFilter = async () => {
    setFilters({
      search: "",
      status: "",
    });
    await setPage(0);
    setPage(1);
  };

  const generateRawHtml = customers.data?.map((customer) => (
    <tr key={customer.id}>
      <td className="text-center">
        <OverlayTrigger placement="bottom" overlay={tooltip}>
          <i
            className="fa-solid fa-copy"
            onClick={() => copyToClipboard(customer.id)}
          ></i>
        </OverlayTrigger>
        &nbsp;&nbsp;
        <textarea
          id={"response_text" + customer.id}
          style={{ display: "none" }}
          defaultValue={customer.customer_json}
        ></textarea>
        <OverlayTrigger placement="bottom" overlay={processCustomerTooltip}>
          <i
            className="fa-solid fa-microchip"
            onClick={() => processCustomer(customer.customer_id)}
          ></i>
        </OverlayTrigger>
      </td>
      <td>{customer.customer_id}</td>
      <td>{customer.shop}</td>
      <td className="text-center">
        {customer.customer_save_status === 1 ? (
          <span className="badge bg-success">Processed</span>
        ) : (
          <span className="badge bg-warning">Unprocessed</span>
        )}
      </td>
      <td>{formatDateTime(customer.created_at)}</td>
    </tr>
  ));

  const loader = () => {
    return (
      <tr>
        <td colSpan={5} className="text-center">
          <div className="loader">
            <svg viewBox="0 0 80 80">
              <circle id="test" cx="40" cy="40" r="32"></circle>
            </svg>
          </div>
        </td>
      </tr>
    );
  };

  useEffect(() => {
    if (page !== 0) {
      getCustomers();
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <div className="container mt-3">
      <ToastContainer autoClose={1500} />
      <h5>
          Database :&nbsp;
          {connection_key === "main_connection"
            ? "Master"
            : connection_key + ".myshopify.com"}
        </h5>
      <div className="filters">
        <div className="row mt-3">
          <div className="col-md-3 col-md-4 col-lg-3 col-sm-6">
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="Search"
              value={filters.search}
              onChange={(event) =>
                setFilters({ ...filters, search: event.target.value })
              }
            />
          </div>
          <div className="col-md-4 col-lg-3 col-sm-6 mt-2 mt-sm-0 mt-lg-0 mt-md-0">
            <select
              className="form-select form-select-sm"
              value={filters.status}
              onChange={(event) =>
                setFilters({ ...filters, status: event.target.value })
              }
            >
              <option value="">Select Status</option>
              <option value={1}>Processed</option>
              <option value={0}>Unprocessed</option>
            </select>
          </div>
          <div className="col-md-4 col-lg-3 col-sm-6 mt-sm-2 mt-lg-0 mt-md-0 mt-2">
            <button
              className="btn btn-primary btn-sm"
              type="button"
              onClick={performFilter}
            >
              <OverlayTrigger placement="bottom" overlay={searchTooltip}>
                <i className="fa-solid fa-magnifying-glass"></i>
              </OverlayTrigger>
            </button>
            &nbsp;
            <button
              className="btn btn-danger btn-sm"
              type="button"
              onClick={resetFilter}
            >
              <OverlayTrigger placement="bottom" overlay={resetTooltip}>
                <i className="fa-solid fa-rotate-right"></i>
              </OverlayTrigger>
            </button>
          </div>
        </div>
      </div>
      <div className="box mt-5">
        <div className="box-body">
          <div className="table-responsive-lg">
            <table className="table sub-table table-striped table-bordered">
              <thead>
                <tr>
                  <th scope="col" className="text-center">
                    Actions
                  </th>
                  <th scope="col">Customer ID</th>
                  <th scope="col">Store Name</th>
                  <th scope="col" className="text-center">
                    Status
                  </th>
                  <th scope="col">Created On</th>
                </tr>
              </thead>
              <tbody>{processing ? loader() : generateRawHtml}</tbody>
            </table>
          </div>
        </div>
        {!processing ? (
          <div className="box-footer">
            <nav>
              <ul className="pagination">
                <li className="page-item">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={paginationTooltipPrev}
                  >
                    <span className="page-link" onClick={handlePrevPage}>
                      <span aria-hidden="true">&laquo;</span>
                    </span>
                  </OverlayTrigger>
                </li>
                <li className="page-item">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={paginationTooltipNext}
                  >
                    <span className="page-link" onClick={handleNextPage}>
                      <span aria-hidden="true">&raquo;</span>
                    </span>
                  </OverlayTrigger>
                </li>
              </ul>
            </nav>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Customer;
