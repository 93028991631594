import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { GET_PLAN_STORE_HISTRY,SEARCH_PLAN_STORE_HISTRY,ALL_STORE_HISTRY } from '../routes';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


const PlanReminders = () => {

    const [processing, setProcessing] = useState(false);

    const [stores, setStores] = useState([]);
    const [storelists, setStoreLists] = useState([]);

    // const [storeDetails, setStoreDetails] = useState(null);

    // const [modalShow, setModalShow] = useState(false);

    const [filters, setFilters] = useState({
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        store: '',
        exhausted: 'exhausted'
    });

    const loader = () => {
        return <tr>
            <td colSpan={9} className='text-center'>
                <div className="loader">
                    <svg viewBox="0 0 80 80">
                        <circle id="test" cx="40" cy="40" r="32"></circle>
                    </svg>
                </div>
            </td>
        </tr>
    }

    const [page, setPage] = useState(1);

    // const tooltip = (
    //     <Tooltip id="tooltip">
    //         <strong>Copy Response</strong>
    //     </Tooltip>
    // );

    // const sendEmail = (
    //     <Tooltip id="sendEmail">
    //         <strong>Send Email</strong>
    //     </Tooltip>
    // );

    // const copyToClipboard = (order_id) => {
    //     var copyText = document.getElementById("response_text" + order_id);
    //     copyText.select();
    //     copyText.setSelectionRange(0, 99999);
    //     navigator.clipboard.writeText(copyText.value);
    //     toast("Copied!");
    // };
 
    const generateRawHtml = stores?.map(store =>
        <tr key={store.id}> 
            <td><span style={{ color:'red' }}>{store.count_of_record}</span></td>
            <td>{store.plan_name } ({store.plan_order_count})</td>
            <td>{store.shop}</td>
            <td>{store.shop_customer_email ? store.shop_customer_email : ''}</td>
            <td>{store.exhausted_at ? store.exhausted_at : ''}</td>
        </tr>
    );

    const generateStoreListHtml = storelists?.map(store =>
        <option value={store.id} key={store.id}>{store.shop}</option>
    );


    const getExhustStoreList = async () => {
        setProcessing(true);
        let res = await axios.post(GET_PLAN_STORE_HISTRY);
        setStores(res.data);
        setProcessing(false);
    }
    const getAllStoreList = async () => {
        setProcessing(true);
        let res = await axios.post(ALL_STORE_HISTRY);
        setStoreLists(res.data);
        setProcessing(false);
    }

    const paginationTooltipPrev = (
        <Tooltip id="paginationTooltipPrev">
            <strong>Prev</strong>
        </Tooltip>
    );

    const paginationTooltipNext = (
        <Tooltip id="paginationTooltipNext">
            <strong>Next</strong>
        </Tooltip>
    );

    const handleNextPage = async () => {
        if (stores.next_page_url !== null) {
            setStores([]);
            setProcessing(true);
            let result = await axios.post(GET_PLAN_STORE_HISTRY + "?page=" + parseInt(page + 1));
            setStores(result.data);
            setProcessing(false);

        }
    };

    const searchTooltip = (
        <Tooltip id="searchTooltip">
            <strong>Search</strong>
        </Tooltip>
    );

    const resetFilter = async () => {
        setFilters({
            month: new Date().getMonth(),
            year: new Date().getFullYear(),
            store: '',
            exhausted: 'exhausted'
        });
        await setPage(0);
        setPage(1);
    };

    const performFilter = async () => {
        setProcessing(true);
        let result = await axios.post(SEARCH_PLAN_STORE_HISTRY + "?page=" + page + "&filters=" + JSON.stringify(filters));
        setStores(result.data);
        setProcessing(false);
    };

    const resetTooltip = (
        <Tooltip id="resetTooltip">
            <strong>Reset</strong>
        </Tooltip>
    );

    const handlePrevPage = async () => {
        if (stores.prev_page_url !== null) {
            setStores([]);
            setProcessing(true);
            let result = await axios.post(GET_PLAN_STORE_HISTRY + "?page=" + parseInt(page - 1));
            setStores(result.data);
            setProcessing(false);

        }
    };
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ];
      const currentYear = new Date().getFullYear();
      const startYear = 1900;
      const years = Array.from({ length: currentYear - startYear + 1 }, (_, index) => currentYear - index);

    useEffect(() => {
        if (page !== 0) {
            getExhustStoreList();
            getAllStoreList();
        }
    }, [page]);

    return (
        <div className="container">
            <ToastContainer autoClose={1500} />
            <div className="filters">
                <div className="row mt-3">
                    
                    <div className="col-md-3 col-md-3 col-lg-2 col-sm-6">
                        <label>Month</label>
                    <select className="form-select form-select-sm" onChange={(event) => setFilters({ ...filters, month: event.target.value })} value={filters.month}>
                        {months.map((month, index) => (
                            <option value={index + 1} key={index} selected={index + 1 === filters.month}>{month}</option>
                        ))}
                    </select>
                    </div>
                    <div className="col-md-3 col-md-2 col-lg-2 col-sm-6">
                    <label>Year</label>
                    <select className="form-select form-select-sm" onChange={(event) => setFilters({ ...filters, year: event.target.value })} value={filters.year}>
                        {years.map((year, index) => (
                            <option value={year} key={index}>{year}</option>
                        ))}
                    </select>
                    </div>
                    <div className="col-md-3 col-lg-3 col-sm-6 mt-2 mt-sm-0 mt-lg-0 mt-md-0">
                        <label>Store</label>
                        <select className="form-select form-select-sm" value={filters.store} onChange={(event) => setFilters({ ...filters, store: event.target.value })}>
                            <option value="">Select Store</option>
                            {generateStoreListHtml}
                        </select>
                    </div>
                    <div className="col-md-3 col-md-4 col-lg-2 col-sm-6">
                        <label>Is Exhausted ?</label>
                    <select className="form-select form-select-sm" onChange={(event) => setFilters({ ...filters, exhausted: event.target.value })} value={filters.exhausted}>
                       <option value="exhausted">Yes</option>
                       <option value="all">No</option>
                    </select>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6 mt-sm-2 mt-lg-0 mt-md-0 mt-2">
                        <button className="btn btn-primary btn-sm mt-4" type="button" onClick={performFilter}>
                            <OverlayTrigger placement="bottom" overlay={searchTooltip}>
                                <i className="fa-solid fa-magnifying-glass"></i>
                            </OverlayTrigger>
                        </button>&nbsp;
                        <button className="btn btn-danger btn-sm mt-4" type="button" onClick={resetFilter}>
                            <OverlayTrigger placement="bottom" overlay={resetTooltip}>
                                <i className="fa-solid fa-rotate-right"></i>
                            </OverlayTrigger>
                        </button>
                    </div>
                </div>
            </div>
            <div className="box mt-5">
                <div className="box-body">
                    <div className='table-responsive-lg'>
                        <table className="table sub-table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">Order Count</th>
                                    <th scope="col">Current Plan</th>
                                    <th scope="col">Store Name</th>
                                    <th scope="col">Store Email</th>
                                    <th scope="col">Exhausted At</th>
                                </tr>
                            </thead>
                            <tbody>
                                {processing ? loader() : (stores?.length === 0 ? 'No data found !! ' :generateRawHtml )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {!processing ? <div className="box-footer">
                <nav>
                    <ul className="pagination">
                        <li className="page-item">
                            <OverlayTrigger placement="bottom" overlay={paginationTooltipPrev}>
                                <span className="page-link" onClick={handlePrevPage}>
                                    <span aria-hidden="true">&laquo;</span>
                                </span>
                            </OverlayTrigger>
                        </li>
                        <li className="page-item">
                            <OverlayTrigger placement="bottom" overlay={paginationTooltipNext}>
                                <span className="page-link" onClick={handleNextPage}>
                                    <span aria-hidden="true">&raquo;</span>
                                </span>
                            </OverlayTrigger>
                        </li>
                    </ul>
                </nav>
            </div> : null}
        </div>
    )
}

export default PlanReminders