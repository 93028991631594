const IsLoggedIn = () => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
        return true;
    } else {
        return false;
    }
}

export {
    IsLoggedIn
}