import axios from "axios";
import React, { useEffect, useState } from "react";
import { GET_SHOPS, GET_TRACKED_DATA } from "../routes";
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import Select from "react-select";

const TrackCustomer = () => {
  const [filters, setFilters] = useState({
    shop_id: "",
    customer: "",
    order: "",
  });

  const [fetching, setFetching] = useState(false);

  const [shops, setShops] = useState([]);

  const [result, setResult] = useState(null);

  const options = [{ value: "", label: "Select Store" }];

  const [dummyOptions, setDummyOptions] = useState({
    value: "",
    label: "Select Store",
  });

  const performFilter = async () => {
    setResult(null);
    setFetching(true);
    const payload = {
      shop_id: filters.shop_id,
      customer: filters.customer,
      order: filters.order,
    };
    let res = await axios.post(GET_TRACKED_DATA, payload);
    await setResult(res.data);
    setFetching(false);
  };

  const resetFilter = () => {
    setFetching(true);
    setFilters({
      shop_id: "",
      customer: "",
      order: "",
    });
    setDummyOptions({ value: "", label: "Select Store" });
    setResult(null);
    setFetching(false);
  };

  const getShops = async () => {
    const result = await axios.post(GET_SHOPS);
    setShops(result.data);
    setFetching(false);
  };

  shops &&
    shops.map((value) =>
      options.push({
        value: value.id,
        label: value.shop,
      })
    );

  // const shopSelectionHTML =
  //   shops &&
  //   shops.map((value) => {
  //     return (
  //       <option value={value.id} key={value.id}>
  //         {value.shop}
  //       </option>
  //     );
  //   });

  const tooltip = (
    <Tooltip id="tooltip">
      <strong>Copy Response</strong>
    </Tooltip>
  );

  const getTextColor = (text, textcolor) => {
    return <div style={{ color: textcolor }}>{text}</div>;
  };

  const copyToClipboard = (args) => {
    var copyText = document.getElementById("response_text" + args);
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
    toast("Copied!");
  };

  const formatDateTime = (value) => {
    return moment(value).format("Do MMM YYYY, h:mm:ss a");
  };

  const storeRawHTML =
    result != null ? (
      <div className="row store-row">
        <div className="col-md-6">
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Shop ID</label>
              </div>
              <div className="col-md-6">
                <strong>{result.id ? result.id : "-"}</strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Store Name</label>
              </div>
              <div className="col-md-6">
                <strong>{result.shop ? result.shop : "-"}</strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Language</label>
              </div>
              <div className="col-md-6">
                <strong>{result.language ? result.language : "-"}</strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Current Language</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.current_language ? result.current_language : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Timezone</label>
              </div>
              <div className="col-md-6">
                <strong>{result.timezone ? result.timezone : "-"}</strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Iana Timezone</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.iana_timezone ? result.iana_timezone : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Omnisend API Key</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.omnisend_api_key ? result.omnisend_api_key : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Time Duration</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.timeDuration ? result.timeDuration : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Expiration Start Date</label>
              </div>
              <div className="col-md-6">
                <strong>{result.start_date ? result.start_date : "-"}</strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Expiration End Date</label>
              </div>
              <div className="col-md-6">
                <strong>{result.end_date ? result.end_date : "-"}</strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Total Days</label>
              </div>
              <div className="col-md-6">
                <strong>{result.total_days ? result.total_days : "-"}</strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Email Reminder Days</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.email_reminder_days
                    ? result.email_reminder_days
                    : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Shopify Token</label>
              </div>
              <div className="col-md-6">
                {result.access_token !== "" ? (
                  <OverlayTrigger placement="bottom" overlay={tooltip}>
                    <i
                      className="fa-solid fa-copy"
                      onClick={() => copyToClipboard("-shopify-token")}
                    ></i>
                  </OverlayTrigger>
                ) : (
                  <strong>-</strong>
                )}
                &nbsp;&nbsp;
                <textarea
                  id="response_text-shopify-token"
                  style={{ display: "none" }}
                  defaultValue={result.access_token}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Plan Name</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.plan.plan_name ? result.plan.plan_name : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Plan Start At</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.plan_start_at
                    ? formatDateTime(result.plan_start_at)
                    : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Plan End At</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.plan_end_at
                    ? formatDateTime(result.plan_end_at)
                    : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Plan Active Response</label>
              </div>
              <div className="col-md-6">
                {result.plan_active_response !== "" ? (
                  <OverlayTrigger placement="bottom" overlay={tooltip}>
                    <i
                      className="fa-solid fa-copy"
                      onClick={() => copyToClipboard("-plan-active-response")}
                    ></i>
                  </OverlayTrigger>
                ) : (
                  <strong>-</strong>
                )}
                &nbsp;&nbsp;
                <textarea
                  id="response_text-plan-active-response"
                  style={{ display: "none" }}
                  defaultValue={result.plan_active_response}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Plan Order Count</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.plan_order_count > result.plan.order_count &&
                  result?.usage_base_shop === 1
                    ? getTextColor(result.plan_order_count, "darkviolet")
                    : result.plan_order_count > result.plan.order_count
                    ? getTextColor(result.plan_order_count, "red")
                    : result.plan_order_count}{" "}
                  ({result?.plan_id ? result?.plan?.order_count : "-"})
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Charge Amount</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.charge_amount ? result.charge_amount : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Plan Status</label>
              </div>
              <div className="col-md-6">
                <strong>{result.plan_status ? result.plan_status : "-"}</strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Order Count Start Date</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.order_count_start_Date
                    ? formatDateTime(result.order_count_start_Date)
                    : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Order Count End Date</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.order_count_end_date
                    ? formatDateTime(result.order_count_end_date)
                    : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Order Count Next Date</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.order_count_next_date
                    ? formatDateTime(result.order_count_next_date)
                    : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Order Count Reset At</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.order_count_reset_at
                    ? formatDateTime(result.order_count_reset_at)
                    : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Store Name</label>
              </div>
              <div className="col-md-6">
                <strong>{result.shop_name ? result.shop_name : "-"}</strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Store Email</label>
              </div>
              <div className="col-md-6">
                <strong>{result.shop_email ? result.shop_email : "-"}</strong>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Preference Contact Email</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.preference_contact_email
                    ? result.preference_contact_email
                    : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Shopify Plan</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.shop_shopify_plan ? result.shop_shopify_plan : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Province</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.shop_province ? result.shop_province : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Money Format</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.shop_money_format ? result.shop_money_format : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Customer Email</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.shop_customer_email
                    ? result.shop_customer_email
                    : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Country Code</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.shop_country_code ? result.shop_country_code : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Country</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.shop_country_name ? result.shop_country_name : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Owner</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.shop_shop_owner ? result.shop_shop_owner : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Province Code</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.shop_province_code ? result.shop_province_code : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Display Badges</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.display_badges_on_front
                    ? result.display_badges_on_front
                    : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Walet On Front</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.display_cashback_wallet_on_front
                    ? result.display_cashback_wallet_on_front
                    : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Online Payment Order</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.online_payment_orders
                    ? result.online_payment_orders
                    : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Offline Payment Orders</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.offline_payment_orders
                    ? result.offline_payment_orders
                    : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Discount Min Perchase Percentage</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.discount_min_perchase_percentage
                    ? result.discount_min_perchase_percentage
                    : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>First Trial End Date</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.first_trial_end_date
                    ? result.first_trial_end_date
                    : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Is 80% Exhuasted</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.is_80_percent_exhausted
                    ? result.is_80_percent_exhausted
                    : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Is 100% Exhuasted</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.is_100_percent_exhausted
                    ? result.is_100_percent_exhausted
                    : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Send Cashback Email</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.do_send_cashback_email
                    ? result.do_send_cashback_email
                    : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Is Development Store</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.is_development_store
                    ? result.is_development_store
                    : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Admin Email</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.shop_admin_email ? result.shop_admin_email : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>AWS Script Updated</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.aws_script_updated ? result.aws_script_updated : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>AWS Setting FIle</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.aws_setting_file ? result.aws_setting_file : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Skip Tour</label>
              </div>
              <div className="col-md-6">
                <strong>{result.skip_tour ? result.skip_tour : "-"}</strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Is Usage Base Shop</label>
              </div>
              <div className="col-md-6">
                <strong>{result.usage_base_shop === 1 ? "Yes" : "No"}</strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Is SMTP Enabled</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result?.is_smtp_saved === 0
                    ? getTextColor("No", "red")
                    : getTextColor("Yes", "blue")}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Created At</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.created_at ? formatDateTime(result.created_at) : "-"}
                </strong>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Updated At</label>
              </div>
              <div className="col-md-6">
                <strong>
                  {result.updated_at ? formatDateTime(result.updated_at) : "-"}
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : null;

  const customerRawHTML =
    result?.customers &&
    result.customers.map((value) => (
      <tr key={value.id}>
        <td>{value.customer_id}</td>
        <td>{value.tier_level ? value.tier_level : "-"}</td>
        <td>{value.tier_order_amt ? value.tier_order_amt : "0.00"}</td>
        <td>
          {value.tier_update_order_date
            ? formatDateTime(value.tier_update_order_date)
            : "-"}
        </td>
        <td>{value.tier_order_id ? value.tier_order_id : "-"}</td>
        <td>
          {value.tier_level_retain_order_total
            ? value.tier_level_retain_order_total
            : "-"}
        </td>
        <td>
          {value.tier_level_retain_date
            ? formatDateTime(value.tier_level_retain_date)
            : "-"}
        </td>
        <td>{value.email ? value.email : "-"}</td>
        <td>
          {value.first_name || value.last_name
            ? value.first_name + " " + value.first_name
            : "-"}
        </td>
        <td>{value.orders_count}</td>
        <td>{value.total_spent}</td>
        <td>{value.last_order_id ? value.last_order_id : "-"}</td>
        <td>{value.tags ? value.tags : "-"}</td>
        <td>{value.is_first_order_cashback}</td>
        <td>{value.referral_cashback ? value.referral_cashback : "-"}</td>
        <td>{value.last_order_name ? value.last_order_name : "-"}</td>
        <td>
          {value.is_referral_link_generated
            ? value.is_referral_link_generated
            : "-"}
        </td>
        <td>{value.referral_code ? value.referral_code : "-"}</td>
        <td>{value.referral_link ? value.referral_link : "-"}</td>
        <td>
          {value.referral_created_at
            ? formatDateTime(value.referral_created_at)
            : "-"}
        </td>
        <td>{value.referral_by ? value.referral_by : "-"}</td>
        <td>
          {value.shopi_created_at
            ? formatDateTime(value.shopi_created_at)
            : "-"}
        </td>
        <td>
          {value.shopi_updated_at
            ? formatDateTime(value.shopi_updated_at)
            : "-"}
        </td>
        <td>{value.created_at ? formatDateTime(value.created_at) : "-"}</td>
        <td>{value.updated_at ? formatDateTime(value.updated_at) : "-"}</td>
      </tr>
    ));

  const orderRawHTML =
    result?.orders &&
    result.orders.map((value) => (
      <tr key={value.id}>
        <td>{value.order_main_id ? value.order_main_id : "-"}</td>
        <td>{value.order_name ? value.order_name : "-"}</td>
        <td>{value.order_number ? value.order_number : "-"}</td>
        <td>{value.order_date ? formatDateTime(value.order_date) : "-"}</td>
        <td>
          {value.order_date_compare
            ? formatDateTime(value.order_date_compare)
            : "-"}
        </td>
        <td>{value.customer_id ? value.customer_id : "-"}</td>
        <td>{value.customer_name ? value.customer_name : "-"}</td>
        <td>{value.subtotal_price ? value.subtotal_price : "-"}</td>
        <td>{value.total_tax ? value.total_tax : "-"}</td>
        <td>{value.total_discounts ? value.total_discounts : "-"}</td>
        <td>{value.total_order_cashback ? value.total_order_cashback : "-"}</td>
        <td>
          {value.order_pending_cashback ? value.order_pending_cashback : "-"}
        </td>
        <td>{value.total_price ? value.total_price : "-"}</td>
        <td>{value.discount_code ? value.discount_code : "-"}</td>
        <td>{value.discount_amount ? value.discount_amount : "-"}</td>
        <td>{value.discount_type ? value.discount_type : "-"}</td>
        <td className="text-center">
          {value.fulfillment_status === "fulfilled" ? (
            <span className="badge bg-secondary">Fulfilled</span>
          ) : (
            <span className="badge bg-secondary">Unfulfilled</span>
          )}
        </td>
        <td>{value.invoice_date ? formatDateTime(value.invoice_date) : "-"}</td>
        <td>
          {value.is_order_with_cashback ? value.is_order_with_cashback : "-"}
        </td>
        <td>{value.is_order_canceled ? value.is_order_canceled : "-"}</td>
        <td>{value.pos_action_id ? value.pos_action_id : "-"}</td>
        <td>{value.created_at ? formatDateTime(value.created_at) : "-"}</td>
        <td>{value.updated_at ? formatDateTime(value.updated_at) : "-"}</td>
      </tr>
    ));

  const searchTooltip = (
    <Tooltip id="searchTooltip">
      <strong>Search</strong>
    </Tooltip>
  );

  const resetTooltip = (
    <Tooltip id="resetTooltip">
      <strong>Reset</strong>
    </Tooltip>
  );

  useEffect(() => {
    getShops();
  }, []);

  return (
    <div className="container">
      <ToastContainer autoClose={1500} />
      <div className="filters">
        <div className="row mt-3">
          <div className="col-md-3 col-lg-3 col-sm-6">
            {shops.length ? (
              <Select
                options={options}
                value={dummyOptions}
                onChange={(event) => {
                  setFilters({ ...filters, shop_id: event.value });
                  setDummyOptions({ value: event.value, label: event.label });
                }}
              />
            ) : (
              <div id="loader"></div>
            )}
          </div>
          <div className="col-md-3 col-lg-3 col-sm-6 mt-2 mt-sm-0 mt-lg-0 mt-md-0">
            <input
              type="text"
              placeholder="Search customer"
              className="form-control"
              value={filters.customer}
              onChange={(event) =>
                setFilters({ ...filters, customer: event.target.value })
              }
            />
          </div>
          <div className="col-md-3 col-lg-3 col-sm-6 mt-2 mt-sm-0 mt-lg-0 mt-md-0">
            <input
              type="text"
              placeholder="Search order"
              className="form-control order-form-control"
              value={filters.order}
              onChange={(event) =>
                setFilters({ ...filters, order: event.target.value })
              }
            />
          </div>
          <div className="col-md-3 col-lg-3 col-sm-6 mt-sm-2 mt-lg-0 mt-md-0 mt-2">
            <button
              className="btn btn-primary btn-sm"
              type="button"
              onClick={performFilter}
              disabled={!filters?.shop_id ? true : false}
            >
              <OverlayTrigger placement="bottom" overlay={searchTooltip}>
                <i className="fa-solid fa-magnifying-glass"></i>
              </OverlayTrigger>
            </button>
            &nbsp;
            <button
              className="btn btn-danger btn-sm"
              type="button"
              onClick={resetFilter}
            >
              <OverlayTrigger placement="bottom" overlay={resetTooltip}>
                <i className="fa-solid fa-rotate-right"></i>
              </OverlayTrigger>
            </button>
          </div>
        </div>
      </div>

      {result ? (
        <div className="box mt-5">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <strong>Store Details</strong>
              </Accordion.Header>
              <Accordion.Body>{storeRawHTML}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <strong>Customer Details</strong>
              </Accordion.Header>
              <Accordion.Body>
                {result?.customers ? (
                  <div className="box mt-2">
                    <div className="box-body">
                      {filters.customer ? (
                        <div className="table-responsive-lg">
                          <table className="table sub-table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th scope="col" className="col-1">
                                  Customer ID
                                </th>
                                <th scope="col" className="col-1">
                                  Tier Level
                                </th>
                                <th scope="col" className="col-1">
                                  Tier Order Amount
                                </th>
                                <th scope="col" className="col-1">
                                  Tier Update Order Date
                                </th>
                                <th scope="col" className="col-1">
                                  Tier Order ID
                                </th>
                                <th scope="col" className="col-1">
                                  Tier Level Retain Order Total
                                </th>
                                <th scope="col" className="col-1">
                                  Tier Level Retain Date
                                </th>
                                <th scope="col" className="col-1">
                                  Email
                                </th>
                                <th scope="col" className="col-1">
                                  Name
                                </th>
                                <th scope="col" className="col-1">
                                  Order Count
                                </th>
                                <th scope="col" className="col-1">
                                  Total Spend
                                </th>
                                <th scope="col" className="col-1">
                                  Last Order ID
                                </th>
                                <th scope="col" className="col-1">
                                  Tags
                                </th>
                                <th scope="col" className="col-1">
                                  Is First Order Cashback
                                </th>
                                <th scope="col" className="col-1">
                                  Referral Cashback
                                </th>
                                <th scope="col" className="col-1">
                                  Last Order Name
                                </th>
                                <th scope="col" className="col-1">
                                  Is Referral Link Generated
                                </th>
                                <th scope="col" className="col-1">
                                  Referral Code
                                </th>
                                <th scope="col" className="col-1">
                                  Referral Link
                                </th>
                                <th scope="col" className="col-1">
                                  Referral Created At
                                </th>
                                <th scope="col" className="col-1">
                                  Referral By
                                </th>
                                <th scope="col" className="col-1">
                                  Shopify Created At
                                </th>
                                <th scope="col" className="col-1">
                                  Shopify Updated At
                                </th>
                                <th scope="col" className="col-1">
                                  Created At
                                </th>
                                <th scope="col" className="col-1">
                                  Updated At
                                </th>
                              </tr>
                            </thead>
                            <tbody>{customerRawHTML}</tbody>
                          </table>
                        </div>
                      ) : (
                        "Type something in customer search field!"
                      )}
                    </div>
                  </div>
                ) : null}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <strong>Orders</strong>
              </Accordion.Header>
              <Accordion.Body>
                <div className="box-body">
                  {filters.order ? (
                    <div className="table-responsive-lg">
                      <table className="table sub-table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">Order Main ID</th>
                            <th scope="col">Order Name</th>
                            <th scope="col">Order Number</th>
                            <th scope="col">Order Date</th>
                            <th scope="col">Order Date Compare</th>
                            <th scope="col">Customer ID</th>
                            <th scope="col">Customer Name</th>
                            <th scope="col">Subtotal Price</th>
                            <th scope="col">Total Tax</th>
                            <th scope="col">Total Discounts</th>
                            <th scope="col">Total Order Cashback</th>
                            <th scope="col">Total Pending Cashback</th>
                            <th scope="col">Total Price</th>
                            <th scope="col">Discount Code</th>
                            <th scope="col">Discount Amount</th>
                            <th scope="col">Discount Type</th>
                            <th scope="col">Order Fulfillment Status</th>
                            <th scope="col">Invoice Date</th>
                            <th scope="col">Is Order With Cashback</th>
                            <th scope="col">Is Order Cancelled</th>
                            <th scope="col">POS Action ID</th>
                            <th scope="col">Created At</th>
                            <th scope="col">Updated At</th>
                          </tr>
                        </thead>
                        <tbody>{orderRawHTML}</tbody>
                      </table>
                    </div>
                  ) : (
                    "Type something in order search field!"
                  )}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      ) : fetching ? (
        <div className="box mt-5 d-flex justify-content-center">
          <div id="loader" style={{ height: "60px", width: "60px" }}></div>
        </div>
      ) : null}
    </div>
  );
};

export default TrackCustomer;
