import axios from 'axios';
import JoditEditor from 'jodit-react';
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { SEND_REMINDER_EMAIL } from '../routes';
import { toast } from 'react-toastify';

const MailModal = (props) => {

    const [payload, setPayload] = useState({
        shop: '',
        to: '',
        subject: '',
        message: ''
    });

    const [sending, setSending] = useState(null);

    const sendMailTo = async () => {
        setSending(true);
        let res = await axios.post(SEND_REMINDER_EMAIL, { data: payload });
        if (res.data.status === 1) {
            setSending(false);
            toast.success('Mail sent successfully');
            setPayload({
                shop: '',
                to: '',
                subject: '',
                message: ''
            });
            props.onHide();
        } else {
            toast.success('Something went wrong!');
        }
    }

    useEffect(() => {
        setPayload({ ...payload, shop: props.storedata ? props.storedata.shop : '', to: props.storedata ? props.storedata.shop_email : '' });
        // eslint-disable-line react-hooks/exhaustive-deps
    }, [props]);

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <small>Send Reminder - {props.storedata?.shop_email}</small>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-3">
                    <strong className="form-label">Subject</strong>
                    <input type="email" className="form-control" value={payload.subject} onChange={(e) => setPayload({ ...payload, subject: e.target.value })} />
                </div>
                <div className="mb-3">
                    <strong className="form-label">Message</strong>
                    <JoditEditor
                        value={payload.message}
                        tabIndex={1}
                        onBlur={(e) => {
                            setPayload({ ...payload, message: e })
                        }}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                {sending ? <div id="loader"></div>
                    : <button className="btn btn-primary" onClick={sendMailTo}>
                        <i className="fa-solid fa-paper-plane" onClick={props.onHide}></i>&nbsp;
                        <strong>Send Mail</strong>
                    </button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default MailModal