import { createContext, useEffect, useState } from "react";
import { GET_ALL_DB_CONNECTIONS } from "../routes";
import axios from "axios";

export const CommonPropsContext = createContext();

const CommonPropsContextProvider = (props) => {
  const [loadingContext, setLoadingContext] = useState(true);
  const [dbConnections, setDBConnections] = useState();
  const getDBConnections = async () => {
    setLoadingContext(true);
    await axios
      .post(GET_ALL_DB_CONNECTIONS)
      .then((result) => {
        setDBConnections(result.data);
      })
      .then(() => {
        setLoadingContext(false);
      });
  };
  useEffect(() => {
    getDBConnections();
  }, []);
  return (
    <CommonPropsContext.Provider
      value={{
        loadingContext,
        setLoadingContext,
        dbConnections,
        getDBConnections,
      }}
    >
      {props.children}
    </CommonPropsContext.Provider>
  );
};

export default CommonPropsContextProvider;
