import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { GET_ACTIVITY_LOGS } from '../routes';

const Activity = () => {
  const { id } = useParams();

  const [logs, setLogs] = useState([]);
  const [loader, setLoader] = useState(true);

  const getActivityLog = (shop_id) => {
    setLoader(true);
    let result = axios.post(GET_ACTIVITY_LOGS, { id: shop_id });
    setLogs(result);
    setLoader(false);
  }

  useEffect(() => {
    getActivityLog(id);
  }, [id]);

  return (
    <div>Activity</div>
  )
}

export default Activity